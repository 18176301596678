import React, { useState, useEffect } from 'react';
import Inc_Sticky_Form from '../Inc_Sticky_Form';
import topImage from '../img/BookKeeping/Bookeeping.jpg'
import {Helmet} from "react-helmet";
export default function Bookkeeping() {
    const [styles, setStyles] = useState({
        position: 'static',
        top: '0px',
        width: '100%',
        visibility: 'visible'
      });
    
      useEffect(() => {
        const handleScroll = () => {
          const mq = window.matchMedia("(min-width: 768px)");
    
          if (mq.matches) {
            const sTop = window.scrollY;
    
            if (sTop > 400 && sTop <= 1400) {
              setStyles({
                position: 'fixed',
                top: '150px',
                width: '30%',
                right:'3%',
                visibility: 'visible'            
              });
            }
    
            if (sTop >1400) {
              setStyles({
                position: 'static',
                top: '150px',
                width: '23%',
                visibility: 'hidden'
              });
            }
    
            if (sTop < 350) {
              setStyles({
                position: 'static',
                top: '0px',
                width: '100%',
                visibility: 'visible'
              });
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <>
    <Helmet>
        <title>Top Business Consultancy & Bookkeeping | Top Consulting Firms</title>
        <meta name="description" content="SBA Tax Consultants for top-notch bookkeeping services and business management consultancy, consulting firms in the USA. Get expert business consultancy services today." />
        <meta name="keywords" content="booking keeping services, Consulting firms in the USA, business management consultancy, business consultancy services." />
    </Helmet>
    <section>
          <img src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
          <div className="container">            
              {/* <div className='bannerText'>
                  <h1>Bookkeeping & Business Consultancy</h1>
                  <p className=''>We provide a range of bookkeeping services that help businesses maintain accurate financial records, understand their financial health, and comply with various regulations. </p>
              </div>             */}
          </div>
          
    </section>
    
    <section>
      <div className="container py-5">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='h2 primary-text'>Bookkeeping Services</h1>
                <h4 className='py-3'>Based on our client requirement , we offer various engagement relating to:</h4>
            
            <div className=" border-bottom">
                <h5 className='primary-text'>Recording Financial Transactions:</h5>   
               <p>Our team will assist in recording day-to-day financial transactions such as sales, purchases, expenses, receipts, and payments. They ensure that all financial activities are accurately documented.</p>
            </div>
       
            <div className=" border-bottom">
                <h5 className='primary-text'>Accounts Payable and Receivable:</h5>
                <p>Managing accounts payable by recording and tracking bills and payments owed, and handling accounts receivable by invoicing clients, tracking payments, and managing collections.</p>
            </div>
       
            <div className=" border-bottom">
                <h5 className='primary-text'>Bank Reconciliations:</h5>
                <p>Our team will assist in reconciling bank statements with the company's records to ensure that all transactions are accounted for and to identify any discrepancies or errors.</p>
            </div>
        
            <div className=" border-bottom">
                <h5 className='primary-text'>Financial Statement Preparation:</h5>
                <p>Our team will assist in preparation of financial statements including income statements, balance sheets, and cash flow statements. These statements provide an overview of the company's financial performance and position.</p>
            </div>
      
            <div className=" border-bottom">
                <h5 className='primary-text'>Budgeting and Forecasting:</h5>
                <p>Assisting in creating budgets and financial forecasts based on historical data and future projections, helping businesses plan for expenses, investments, and growth.</p>
            </div>
        
            <div className=" border-bottom">
                <h5 className='primary-text'>Payroll Processing:</h5>
                <p>Handling payroll activities including calculating wages, processing payments, withholding taxes, and ensuring compliance with payroll tax regulations.</p>
            </div>
       
            <div className=" border-bottom">
                <h5 className='primary-text'>Tax Preparation and Compliance:</h5>
                <p>Assisting in preparing and filing various tax returns, ensuring compliance with tax laws and regulations, and providing guidance on tax-saving strategies.</p>
            </div>
       
            <div className=" border-bottom">
                <h5 className='primary-text'>Financial Analysis and Reporting:</h5>
                <p>Analyzing financial data to provide insights into business performance, trends, and areas for improvement. CPAs generate reports and provide analysis to aid in decision-making.</p>
            </div>
        
            <div className=" border-bottom">
                <h5 className='primary-text'>Audit Preparation and Support:</h5>
                <p>Assisting during financial audits by preparing necessary documents, explaining financial records, and ensuring compliance with auditing standards.</p>
            </div>
        
            <div className=" border-bottom">
                <h5 className='primary-text'>Advisory Services:</h5>
                <p>Providing financial advice and recommendations to help businesses improve financial processes, internal controls, and overall efficiency.</p>
            </div>
        
            <div className="">                
                <p>Our team play's a critical role in maintaining accurate financial records, ensuring compliance with regulations, and providing valuable insights that enable businesses to make informed financial decisions.</p>
            </div>
        </div>
        <div className="col-md-4 fixedElement" style={styles}>
            <Inc_Sticky_Form />
        </div>
      </div>
      </div>
    </section>
    </>
  )
}
