import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/Tobacco-Licenses-in-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const TobaccoLicensesInUSA = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>Get Your Tobacco License in USA with SBA Tax Consultants</title>
        <meta name="description" content="Apply for a tobacco license in the USA with SBA Tax Consultants. We handle applications, renewals, and licensing requirements." />
        <meta name="keywords" content="Tobacco License Application USA, Tobacco Retail License USA, Buy Tobacco License USA, Tobacco Licensing Requirements USA, Tobacco License Renewal USA" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Tobacco Licenses in USA</strong></h1>
                <p className='text-white py-3 ppp'>Looking to obtain a tobacco license in the USA? SBA Tax Consultants offer expert guidance on tobacco licensing requirements and applications. Contact us for streamlined, professional assistance.</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Tobacco Licensing Services with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Navigating the Tobacco License Application USA process can be challenging, but we streamline the experience for you at SBA Tax Consultants. Whether you're seeking a Tobacco Retail License or need assistance with your Tobacco License Application USA, our expert team is here to help. We understand the intricate Tobacco Licensing Requirements USA and ensure your application Meets all necessary criteria.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default TobaccoLicensesInUSA;
