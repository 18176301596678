import React from 'react'
import Img from '../img/about-sba-tax.webp'
import akMerchant from '../img/ak-merchant.png'
import BharathSir from '../img/bharat-sir.jpeg'


export default function AboutH() {
  return (

    <>  
        
        <section className='py-5' id='aboutHome'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h1 className='h2 fw-bold text-center primary-text'>SBA Tax Consultant in USA</h1>
                    </div>
                    <div className="col-md-12 text-center py-3 mb-3">
                        <button className='btn btn-danger rounded-pill px-4 mb-2'>Who we are?</button> &nbsp;&nbsp;&nbsp;
                        <button className='btn btn-danger rounded-pill px-4 mb-2'>What we do?</button> &nbsp;&nbsp;&nbsp;
                        
                        <button className='btn btn-danger rounded-pill px-5 mb-2'>Service</button> &nbsp;&nbsp;&nbsp;
                        <button className='btn btn-danger rounded-pill px-4 mb-2'>Financial Planning</button> &nbsp;&nbsp;&nbsp;
                    </div>
                    
                    <div className="col-md-7">
                       
                        <p>Welcome to SBA Tax Consultants, your go-to source for all of your tax and accounting needs. Our company was founded by team of <span className='primary-text fw-bold'>Professionals and a Certified Public Accountants (CPA)</span> who have extensive experience in the financial industry. With our knowledgeable staff and commitment to excellence, we strive to provide top-notch Tax Advisory Services to individuals and businesses alike.</p>
                        
                        <p>At SBA Tax Consultants, we understand the complexities of tax law and the importance of financial planning for both individuals and businesses. Our team consists of <span className=''>highly skilled CPAs and other Professionals</span>, who have a deep understanding of the ever-changing tax regulations in the United States. We are dedicated to helping our clients navigate the intricate world of taxes, ensuring that they receive all applicable deductions and credits while remaining compliant with all laws.</p>
                        
                        <p>We offer a wide range of services to meet all of your tax and accounting needs. Our team is well-versed in handling personal and corporate taxes, as well as providing assistance with payroll and other financial tasks necessary for running a successful business. We work closely with our clients to create personalized solutions tailored to their specific needs.</p>
                        <p>Expert Tax Consultants for Personalized Financial Solutions at SBA Tax Consultants, we pride ourselves on being your trusted partner in all tax and accounting matters.</p>
                        <a href="./about-us"><button className="btn btn-danger">Know more about us.</button></a>
                    </div>
                    <div className="col-md-5">
                        <img src={Img} alt="About SBA Tax Consultant" className="img-fluid" />
                    </div>
                    
                </div>
            </div>
        </section>

        <section>
            <div class="container">
                <div className="row align-items-center justify-content-center" id='FounderSec'>
                
                <div className="col-md-12">
                    <h3 className='fw-bold primary-text text-center mb-5'>Founders</h3>
                    <div className="row align-items-center border-bottom pb-3">
                        <div className="col-md-3">
                            <img src={akMerchant} alt="img" className='img-fluid shadow border' />
                        </div>
                        <div className="col-md-9">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-quote" viewBox="0 0 16 16">
                                <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1z"/>
                            </svg> */}
                            {/* <p><span className="h1">G</span>et addicted towards your passion in such a way, that life's unpredictable turning challenges will become your stepping stones towards your success.
                                More than two decades of consistent performance has helped us to achieve the right place. 
                            </p> */}
                            <h4 className='pt-3 fw-bold'>Akshay Merchant</h4>
                            <h6 className='primary-text'>Chief Operations Officer</h6>
                            <h6 className='mt-4'>Qualication: </h6>
                            <h6 className='secondary-text'> CPA , CMA , EA , CSCA , CA (India) , FIII , MCom & BMS</h6>
                        
                            <p>A successful individual, adept in analytical and creative thinking, navigates challenges with strategic precision. Their motivation and self-awareness serve as catalysts, driving not only personal growth but inspiring teams to excel.</p>
                            <a href="./about-us" className='btn btn-danger rounded-pill'>Know More</a>
                            <p></p>
                            
                        </div>                        
                    </div>

                    <div className="row align-items-center border-bottom pb-3 mt-5">
                        
                        <div className="col-md-9">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-quote" viewBox="0 0 16 16">
                                <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1z"/>
                            </svg> */}
                            {/* <p>Innovation drives our vision. With dedication and expertise, we pave the path to excellence in every venture. Over the last decade, his innovative business aspects helped many people to achieve their professions.</p> */}
                            <h4 className='fw-bold pt-3'>Dr. Bharath Kumar Kakkireni</h4>
                            <h6 className='primary-text'>Chief Technical Advisor</h6>
                            <h6 className='mt-4'>Qualication: </h6>
                            <h6 className='secondary-text'> B.Pharm, MSM, MS, USA</h6>
                            
                            <p>A sharp focus businessman Dr. K.Bharath Kumar is the Chairman and CEO of KBK Group of companies. From Hospital to IT, his passion and effort brings him to lead the success. With the right attitude, you can achieve success in any field.</p>
                            <a href="./about-us" className='btn btn-danger rounded-pill'>Know More</a>
                            <p></p>
                            
                        </div>
                        <div className="col-md-3">
                            <img src={BharathSir} alt="img" className='img-fluid' />
                        </div>
                        
                        
                    </div>
                </div>

                </div>
            </div>
        </section>
    </>
  )
}
