import React from 'react'
import icon1 from '../img/icons/accuracy.png'
import icon2 from '../img/icons/daylight-saving-time.png'
import icon3 from '../img/icons/stress-management.png'

export default function WhyChoose() {
  return (
    <section className='bg-newwww position-relative'>
      <div className="container py-5 dddd">
        <div className="row justify-content-center align-items-center mb-4">
          <div className="col-md-6 text-center">
            <h2 className='h1 text-white'><b>Why choose us</b></h2>
            <p className='text-white'>Professionalism is not just a trait; it's our commitment. Trust in excellence with every service we provide.</p>
          </div>
        </div>
        <div className="row align-items-center">          
            <div className="col-md-4"> 
              <div className="service--box bg-white p-4 text-center rounded">
                <img src={icon1} alt="" className="img-fluid iconns mx-auto mb-3" />
                <h3 className='fw-bold text-dark text-center'><strong> Accuracy</strong></h3>
                <p className='pp pt-2'><span className='text-dark'>At SBA Tax Consultants, we prioritize accuracy in all our services. From obtaining occupancy certificates to managing complex tax issues, our meticulous approach ensures precise compliance and flawless results.</span></p>
              </div> 
            </div>

            <div className="col-md-4"> 
              <div className="service--box bg-white p-4 text-center rounded">
                <img src={icon2} alt="" className="img-fluid iconns mx-auto mb-3" />
                <h3 className='fw-bold text-dark text-center'><strong>Time saving</strong></h3>
                <p className='pp pt-2'><span className='text-dark'>Simplify your tax and occupancy certification with our expert services. We manage the details, letting you concentrate on what’s important while saving you time and effort.</span></p>
              </div> 
            </div>

            <div className="col-md-4"> 
              <div className="service--box bg-white p-4 text-center rounded">
                <img src={icon3} alt="" className="img-fluid iconns mx-auto mb-3" />
                <h3 className='fw-bold text-dark text-center'><strong> Stress free </strong></h3>
                <p className='pp pt-2'><span className='text-dark'>Enjoy peace of mind with our hassle-free occupancy certificate services. Our expert team manages every detail, ensuring your property meets legal requirements efficiently and effortlessly.</span></p>
              </div> 
            </div>
        
        </div>
        </div>
    </section>
  )
}
