import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/Establishing-a-New-Corporation-in-the-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const NewCorporationInTheUSA = () => {
  


  
    
  return (
    <>
    <Helmet>
        <title>Incorporating a Business in the USA | SBA Tax Consultants</title>
        <meta name="description" content="Start a corporation or set up a new business in the USA with SBA Tax Consultants' expert incorporation services." />
        <meta name="keywords" content="Incorporating a Business in the USA, Start a Corporation USA, New Corporation Setup USA, USA Business Incorporation Services, Forming a Corporation in America" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Establishing a New Corporation in the USA</strong></h1>
                <p className='text-white py-3 ppp'>"Establishing a new corporation in the USA? SBA Tax Consultants offer expert guidance on incorporation, ensuring compliance and smooth setup. Contact us for tailored support."</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Incorporating a Business in the USA with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>"Ready to start a corporation in the USA? At SBA Tax Consultants, we specialize in USA Business Incorporation Services to streamline your new corporation setup. Whether you're incorporating a business in the USA for the first time or need expert guidance, our experienced team is here to assist you at every step. Trust us to make your business incorporation process smooth and efficient."
            </p>
            
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default NewCorporationInTheUSA;


